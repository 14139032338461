import React from 'react'
import { Link, graphql } from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const Page404 = props => {
  const { t } = useTranslation()
  return (
    <Layout {...props}>
      <Seo title={t('Page Not Found')} />
      <Divider />
      <Stack>
        <Main>
          <Section>
            <PageTitle
              header="Sorry, this page isn't available."
              subheader='You may have mistyped the address or the page may have moved.'
            />
          </Section>
          <Section>
            <Button variant='primary' as={Link} to='/'>
              <Trans>Go to homepage</Trans>
            </Button>
          </Section>
        </Main>
      </Stack>
    </Layout>
  )
}

export default Page404

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`
